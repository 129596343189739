<template>
  <div class="page-content vehicle-info">
    <div class="page-form content">
      <Form
        :ref="`form-vehicle`"
        :defaultForm.sync="form"
        :fields="fields"
        :defaultText.sync="fieldTexts"
      >
        <van-field
          slot="field-custom-vin"
          v-model.trim="form.vin"
          label="车架号"
          disabled
          input-align="right"
        >
          <template #button>
            <div
              v-if="!assessSuccess"
              :class="['field-btn', assessIng ? 'ing' : '']"
              @click="assessFn"
            >
              {{ assessIng ? "评估中 " : "去评估" }}
            </div>
            <span v-else class="field-btn suc">已评估</span>
          </template>
        </van-field>
      </Form>
    </div>

    <div class="page-btn btns">
      <van-button type="info" size="small" @click="submitFn">提交</van-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  assessVehicle,
  createVehicleInfo,
  orderDetailInfo,
  assessChannel
} from "@/api/apply";
import { setFormData } from "../dev_form_json";
import { getSomeEnums } from "@/api/enums";
export default {
  components: {
    Form: () => import("@/components/Form/Index")
  },
  data() {
    return {
      required: [
        {
          required: true
        }
      ],
      bizNo: "",
      assessIng: false,
      evalStatus: false,
      assessSuccess: false,
      form: {},
      enums: {},
      fieldTexts: {}
    };
  },
  computed: {
    fields() {
      return [
        {
          type: "text",
          label: "车牌",
          disabled: true,
          key: "carPlateNum"
        },
        {
          type: "custom",
          label: "车架号",
          key: "vin"
        },
        {
          type: "text",
          label: "车辆颜色",
          required: true,
          key: "carColor"
        },
        // {
        //   type: "region",
        //   label: "车辆上牌地",
        //   required: true,
        //   namesKey: ["licenseProvinceName", "licenseCityName"],
        //   keys: ["licenseProvinceCode", "licenseCityCode"],
        //   key: "licenseCode"
        // },
        {
          type: "amount",
          label: "购买金额(元)",
          decimal: 2,
          required: true,
          key: "purchaseAmount"
        },
        {
          type: "text",
          label: "受理地",
          required: true,
          key: "acceptLocation"
        },
        {
          type: "number",
          label: "过户次数",
          required: true,
          key: "transferNumber"
        },
        {
          type: "number",
          label: "一年内过户次数",
          labelWidth: "7.2em",
          required: true,
          key: "yearTransferNumber"
        },
        {
          type: "text",
          label: "车辆准载限制",
          required: true,
          key: "loadLimit"
        },
        {
          type: "select",
          label: "车辆是否解押",
          required: true,
          key: "releasePledge",
          enums: this.enums?.pledge || []
        }
      ];
    }
  },
  mounted() {
    this.getAllEnums();
    this.init();

    window.agilityAutoFill = this.agilityAutoFill;
  },
  methods: {
    ...mapGetters(["getUserOrderInfo"]),
    init() {
      const data = this.getUserOrderInfo();
      this.bizNo = data.bizNo || "";
      this.getApplyInfo();
      this.getAssessChannel();
    },
    async getAssessChannel() {
      try {
        const { data } = await assessChannel(this.bizNo);
        this.evalStatus = data.evalStatus == "Y";
      } catch (error) {
        this.$errMsg(error);
      }
    },
    async getAllEnums() {
      try {
        const [d1] = await Promise.all([getSomeEnums("pledge")]);

        this.$set(this.enums, "pledge", d1);
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // 申请信息
    async getApplyInfo() {
      try {
        this.loading = true;
        this.$toast.loading({
          forbidClick: true,
          duration: 0
        });
        const { data } = await orderDetailInfo(this.bizNo);
        const { carPlateNum, vin, assessStatus } = data;
        this.$set(this.form, "carPlateNum", carPlateNum);
        this.$set(this.form, "vin", vin);
        // this.assessSuccess = evalStatus == "0";
        this.assessSuccess = assessStatus;
        // this.assessIng = evalStatus == "2";
        this.$toast.clear();
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.loading = false;
      }
    },
    async assessFn() {
      if (this.assessIng) {
        this.$errMsg("车辆评估中");
        return;
      }
      if (this.evalStatus) this.$router.push("/vehicle-assess");
      else {
        try {
          this.assessIng = true;
          await assessVehicle(this.bizNo);
          this.getApplyInfo();
        } catch (error) {
          this.$errMsg(error);
        } finally {
          this.assessIng = false;
        }
      }
    },
    async submitFn() {
      try {
        if (!this.assessSuccess) throw "请先发起评估";
        const infodom = this.$refs["form-vehicle"];
        await infodom.validate();
        const params = {
          bizNo: this.bizNo,
          ...this.form
        };
        this.$toast.loading({
          message: "处理中...",
          forbidClick: true,
          duration: 0
        });
        await createVehicleInfo(params);
        this.$toast.clear();
        this.$router.push("/apply-detail");
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // 开发快速调试
    agilityAutoFill() {
      let isdev = process.env.NODE_ENV === "development";
      if (isdev) {
        setFormData(this, "form", "apply_form_data_car_form");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./input-style.scss";
.vehicle-info {
  .field-btn {
    &.ing {
      color: #666;
    }
    &.suc {
      color: #52c41a;
    }
  }
  .btns {
    .van-button {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
